import ConnectBankButton from 'components/features/CTAs/ConnectBank/Button';
import AccountFrozenButton from 'components/features/CTAs/UserStatus/components/LoggedInStatus/components/AccountFrozenButton';
import Button, { ButtonProps } from 'components/generics/buttons/Button';
import { OPEN_ACCOUNT } from 'consts/analytics/KYCEvents';
import {
  FULL_NAME_ROUTE,
  KYC_ROUTE,
  RESIDENTS_KYC_ROUTE,
} from 'consts/routes/urls';
import { USER_STATUS } from 'consts/User/status';
import { analytics } from 'hooks/integrations/mixpanel/useMixpanel';
import { useLandaRouter } from 'hooks/logics/useLandaRouter';
import useHasBankConnection from 'hooks/requests/financials/useHasBankConnection';
import useKyc from 'hooks/requests/kyc/useKyc';
import useUserDetails from 'hooks/requests/user/hooks/useUserDetails';
import React from 'react';

const LoggedInStatus = ({ className }: ButtonProps): JSX.Element | null => {
  const { navigate } = useLandaRouter();
  const { userDetails } = useUserDetails();
  const userStage = userDetails?.userStage;
  const { kycIdentities } = useKyc();

  const { hasBankConnection, loading: bankLoading } = useHasBankConnection();
  const onClick = () => {
    if (userStage === USER_STATUS.ACCOUNT_RETRY) return null;
    analytics.track(OPEN_ACCOUNT);
    if (userStage === USER_STATUS.ACCOUNT_BEFORE_KYC) {
      navigate(userDetails?.isTenant ? RESIDENTS_KYC_ROUTE : KYC_ROUTE);
    } else {
      navigate(FULL_NAME_ROUTE);
    }
  };
  if (kycIdentities?.attributes?.verification_status === 'frozen') {
    return <AccountFrozenButton className={className} />;
  }
  if (hasBankConnection) return null;
  if (userStage === USER_STATUS.ACCOUNT_SUSPENDED) return null;
  // if (userDetails?.isTenant) return null;

  const bankStage = !(userStage === USER_STATUS.ACCOUNT_BEFORE_KYC);

  if (bankStage) {
    if (bankLoading) {
      return null;
    }
    return <ConnectBankButton className={className} />;
  }

  return (
    <Button
      className={className}
      onClick={onClick}
    >
      Open Account
    </Button>
  );
};

export default LoggedInStatus;
