import { USER_STATUS } from 'consts/User/status';
import useBankName from 'hooks/requests/financials/useBankName';
import useHasBankConnection from 'hooks/requests/financials/useHasBankConnection';
import useUserDetails from 'hooks/requests/user/hooks/useUserDetails';
import { getBankStateProps } from 'pages/profile/components/ProfileTabContent/Bank&Transfers/components/BankStateSection/utils';
import { useState } from 'react';
import useGlobalLoader from 'stores/GlobalLoader/globalLoader';
import useLandaDialogs from '../../../../../../../components/dialogs/hooks/useLandaDialogs';
import { LANDA_DIALOGS } from '../../../../../../../components/dialogs/utils';
import { useLandaRouter } from 'hooks/logics/useLandaRouter';
import { useLandaApiMutation } from 'hooks/api/graphql/landa_api';
import { ACCOUNT_DETAILS } from 'graphql/mutations/accountDetailsMutation';

const useBankStateSectionData = () => {
  const { hasBankConnection, loading: hasBankConnectionLoading } =
    useHasBankConnection();
  const { bankLoading } = useGlobalLoader();
  const [accountDetails] = useLandaApiMutation(ACCOUNT_DETAILS);
  const [isMicroDepositOpen, setIsMicroDepositOpen] = useState(false);

  const {
    bankName,
    accountLastDigits,
    loadingBankConnection: bankNameLoading,
  } = useBankName();

  const { userDetails, loading: userLoading } = useUserDetails();
  const { navigate } = useLandaRouter();
  const { openDialog } = useLandaDialogs();
  const openBankDialog = () => openDialog(LANDA_DIALOGS.MANUAL_BANK_CONNECT);
  const openMicroDepositDialog = () => setIsMicroDepositOpen(true);
  const closeMicroDepositDialog = () => setIsMicroDepositOpen(false);
  const [disconnectOpen, setDisconnectOpen] = useState(false);

  const contactUsLink = () => {
    navigate('https://www.landa.app/contact-us', {
      href: 'https://www.landa.app/contact-us',
    });
  };

  const {
    bankTitle,
    accountDigits,
    buttonText,
    disabled,
    onClick,
    disconnectClick,
    userState,
  } = getBankStateProps(
    bankName,
    hasBankConnection,
    userDetails?.userStage as USER_STATUS,
    accountLastDigits,
    openBankDialog,
    openMicroDepositDialog,
    () => setDisconnectOpen(true),
    contactUsLink
  );

  const loading = hasBankConnectionLoading || bankNameLoading || bankLoading;

  return {
    bankTitle,
    accountDigits,
    buttonText,
    disabled,
    onClick,
    disconnectClick,
    loading,
    disconnectOpen,
    setDisconnectOpen,
    hasBankConnection,
    accountDetails,
    userState,
    isMicroDepositOpen,
    closeMicroDepositDialog,
    openBankDialog,
  };
};

export default useBankStateSectionData;
