import React, { useState } from 'react';
import classes from './index.module.scss';
import { HEADER_MENUS, LINKS } from './consts';
import { useLandaRouter } from 'hooks/logics/useLandaRouter';
import SubMenu from './components/SubMenu';
import Button from 'components/generics/buttons/Button';
import Typography from 'components/generics/texts/Typography';
import { HEADER_MENU } from 'consts/enums/headerMenu';

const HeaderMenu = () => {
  const { navigate } = useLandaRouter();
  const [open, setOpen] = useState(false);
  const [menuStatus, setMenuStatus] = useState('');
  const toggleOpen = () => setOpen(!open);

  const onMenuClickHandler = (menu: string) => {
    switch (menu) {
      case HEADER_MENU.ABOUT_US:
        navigate({
          pathname: LINKS.ABOUT_US,
        });
        break;
      case HEADER_MENU.HELP:
        toggleOpen();
        setMenuStatus(menu);
        break;
      case HEADER_MENU.LEARN:
        toggleOpen();
        setMenuStatus(menu);
        break;
    }
  };

  return (
    <Typography className={classes.container}>
      {HEADER_MENUS.map(({ text, Icon, subMenu }, index) => (
        <Typography key={index.toString()}>
          <Button
            variant={'text'}
            onClick={() => onMenuClickHandler(text)}
          >
            {text}
            {Icon && <Icon className={classes.arrow} />}
          </Button>
          {menuStatus === text && open && <SubMenu items={subMenu} />}
        </Typography>
      ))}
    </Typography>
  );
};

export default HeaderMenu;
