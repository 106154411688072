import classNames from 'classnames';
import classes from 'components/features/CTAs/ConnectBank/Button/index.module.scss';
import {
  BUTTON_ON_CLICK_DIALOG,
  BUTTON_OPTIONS,
} from 'components/features/CTAs/ConnectBank/consts';
import Button from 'components/generics/buttons/Button';
import Typography from 'components/generics/texts/Typography';
import { USER_STATUS } from 'consts/User/status';
import useUserDetails from 'hooks/requests/user/hooks/useUserDetails';
import BankIcon from 'public/assets/icons/bank.svg';
import React, { HTMLAttributes, useState } from 'react';
import useGlobalLoader from 'stores/GlobalLoader/globalLoader';
import useLandaDialogs from '../../../../dialogs/hooks/useLandaDialogs';
import BankConnectionModal from 'pages/profile/components/ProfileTabContent/Bank&Transfers/components/BankStateSection/components/BankConnectionModal';

type ConnectBankButtonProps = HTMLAttributes<HTMLButtonElement>;

const ConnectBankButton = ({ className, ...props }: ConnectBankButtonProps) => {
  const { bankLoading } = useGlobalLoader();
  const { userDetails } = useUserDetails();
  const { openDialog } = useLandaDialogs();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const buttonText =
    (userDetails && BUTTON_OPTIONS[userDetails?.userStage]) ||
    BUTTON_OPTIONS[USER_STATUS.DEFAULT];
  const buttonDialog =
    (userDetails && BUTTON_ON_CLICK_DIALOG[userDetails?.userStage]) ||
    BUTTON_ON_CLICK_DIALOG[USER_STATUS.DEFAULT];

  if (bankLoading) return null;

  const handleClick = () => {
    if (buttonDialog === BUTTON_ON_CLICK_DIALOG[USER_STATUS.DEFAULT]) {
      setIsModalOpen(true);
    } else {
      openDialog(buttonDialog);
    }
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <BankConnectionModal
        onClose={handleClose}
        modalOpen={isModalOpen}
        onClick={() => openDialog(buttonDialog)}
      />
      <Button
        {...props}
        onClick={handleClick}
        className={classNames(classes.bankButton, className)}
      >
        <div className={classes.innerContainer}>
          <BankIcon className={classes.icon} />
          <Typography>{buttonText}</Typography>
        </div>
      </Button>
    </>
  );
};

export default ConnectBankButton;
