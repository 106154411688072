import classNames from 'classnames';
import classes from 'components/features/CTAs/UserStatus/components/NotLoggedInStatus/index.module.scss';
import Button, { ButtonProps } from 'components/generics/buttons/Button';
import { GET_STARTED } from 'consts/analytics/onBoardingEvents';
import { SIGNUP_ROUTE } from 'consts/routes/urls';
import { analytics } from 'hooks/integrations/mixpanel/useMixpanel';
import { useLandaRouter } from 'hooks/logics/useLandaRouter';
import React from 'react';

const NotLoggedInStatus = ({ className }: ButtonProps): JSX.Element | null => {
  const { navigate } = useLandaRouter();

  return (
    <Button
      className={classNames(classes.button, classes.signUpButton, className)}
      onClick={() => {
        analytics.track(GET_STARTED);
        navigate(SIGNUP_ROUTE);
      }}
    >
      Get Started
    </Button>
  );
};

export default NotLoggedInStatus;
