import { PERSONAL_INFORMATION_QUERY } from 'graphql/kycIdentity/queries/personalInformationQuery';
import { useLandaApiLazyQuery } from 'hooks/api/graphql/landa_api';
import { useEffect } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { useKycStore } from 'stores/KYCIdentities/kycIdentities';
import { PersonalInformation } from 'types/kyc-identities-types';

const init: { personalInformation?: PersonalInformation; loading: boolean } = {
  personalInformation: undefined,
  loading: false,
};

const usePersonalInformation = () => {
  const { kycIdentities, setPersonalInformation } = useKycStore();

  const [fetchPersonalInformation, { loading }] = useLandaApiLazyQuery(
    PERSONAL_INFORMATION_QUERY,
    {
      fetchPolicy: 'network-only',
    }
  );
  const personalInformation = kycIdentities?.attributes?.personal_information;

  useEffect(() => {
    if (!personalInformation) {
      fetchPersonalInformation({
        onCompleted: ({ kycIdentity }) => {
          setPersonalInformation(kycIdentity);
        },
      });
    }
  }, [personalInformation]);

  return { personalInformation, loading };
};

export default singletonHook(init, usePersonalInformation);
