import { PortfolioTransactionLimitsType } from '../types/portfolio-types';
import { FieldValidator } from 'final-form';

const EMAIL_VALIDATION_REGEX =
  /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
const PHONE_VALIDATION_REGEX = /^1\d{10}$/;
const FULL_NAME_REGEX = /^[a-zA-Z ,.'-]{2,} ([a-zA-Z ,.'-]{2,})+$/;
export const MAX_TRANSACTION_AMOUNT = 99999;

type InputValidator = (
  value?: string,
  options?: any
) => {
  isValid: boolean;
  errorMessage: string;
};

export const isEmailValid: InputValidator = (value?: string) => {
  if (!value) return { isValid: false, errorMessage: '' };
  const valid = !!value?.match(EMAIL_VALIDATION_REGEX);
  return {
    isValid: valid,
    errorMessage: valid ? '' : 'Please enter a valid email address',
  };
};

export const isPhoneValid: InputValidator = (value?: string) => {
  if (!value) return { isValid: false, errorMessage: '' };

  const valid = !!value?.match(PHONE_VALIDATION_REGEX);
  return {
    isValid: valid,
    errorMessage: valid ? '' : 'Please enter a valid phone number',
  };
};

export const isDateValid: InputValidator = (
  value?: string,
  options?: { validateOver18?: boolean }
) => {
  if (!value) return { isValid: false, errorMessage: '' };
  const [month, day, year] = value.split('/');
  if (!year || !month || !day)
    return {
      isValid: false,
      errorMessage: 'Please enter a valid date',
    };
  if (day.length !== 2 || month.length !== 2 || year.length !== 4)
    return {
      isValid: false,
      errorMessage: 'Please enter a valid date',
    };
  const dateEntered = new Date(`${month}/${day}/${year}`);
  if (dateEntered.toString() === 'Invalid Date')
    return {
      isValid: false,
      errorMessage: 'Please enter a valid date',
    };
  if (options?.validateOver18) {
    const currentYear = new Date();
    const diff =
      (currentYear.getTime() - dateEntered.getTime()) / 1000 / (60 * 60 * 24);
    const yearDiff = Math.round(diff / 365.25);
    if (yearDiff < 0) {
      return {
        isValid: false,
        errorMessage: 'Please enter a valid date',
      };
    }
    if (yearDiff < 18)
      return {
        isValid: false,
        errorMessage: 'You must be 18 years or older to use this service',
      };
  }
  const valid = dateEntered.getFullYear() >= 1900;
  return {
    isValid: valid,
    errorMessage: valid ? '' : 'Please enter a valid birthdate',
  };
};

export const isFullNameValid: InputValidator = (value?: string) => {
  if (!value) return { isValid: false, errorMessage: '' };
  const valid = !!value?.match(FULL_NAME_REGEX);
  return {
    isValid: valid,
    errorMessage: valid ? '' : 'Please enter a valid full name',
  };
};

export const validateBirthDate = (value: string) => {
  if (!value) return;
  const [month, day, year] = value.split('/');
  if (month?.length === 2 && (Number(month) > 12 || Number(month) < 1))
    return 'Month value should be from 1 to 12';
  if (day?.length === 2 && (Number(day) > 31 || Number(day) < 1))
    return 'Day value should be from 1 to 31';
  if (year?.length === 4) {
    const currentYear = new Date().getFullYear();
    if (Number(year) < 1900) {
      return `Seems like you have surpassed ${
        currentYear - Number(year)
      } years.`;
    }
    if (Number(year) > currentYear - 18) {
      return 'You must be 18 years old or older to join Landa.';
    }
  }
};

export const validateNetWorth = (value: string): boolean => {
  const numberValue = Number(value);
  if (Number.isNaN(numberValue)) return false;
  const isValid = !(
    numberValue >= 5000 ||
    (numberValue <= 0 && value.length !== 0)
  );
  return isValid;
};

export const validateAnnualIncome = (value: string): boolean => {
  const numberValue = Number(value);
  if (Number.isNaN(numberValue)) return false;
  const isValid = !(
    numberValue >= 5000 ||
    (numberValue <= 0 && value.length !== 0)
  );
  return isValid;
};

export const transactionValidator: InputValidator = (
  value?: string | undefined,
  options?: {
    maxAmount?: number;
    portfolioLimits?: PortfolioTransactionLimitsType;
  }
) => {
  if (!value) {
    return {
      isValid: false,
      errorMessage: '',
    };
  }
  const actualAmount = Number(value);
  if (actualAmount <= 0) {
    return {
      isValid: false,
      errorMessage: '',
    };
  }
  if (options?.portfolioLimits) {
    Object.entries(options.portfolioLimits).forEach(([key, value]) => {
      if (actualAmount > value) {
        return {
          isValid: false,
          errorMessage: `You can transfer up to ${
            '$' + value.toString().addCommaToNumbers()
          } per ${key}`,
        };
      }
    });
  }
  if (options?.maxAmount && actualAmount > options?.maxAmount) {
    return {
      isValid: false,
      errorMessage: `You can transfer up to ${
        '$' + options.maxAmount.toString().addCommaToNumbers()
      }`,
    };
  }
  return {
    isValid: true,
    errorMessage: '',
  };
};
