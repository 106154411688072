import TransferDialog from 'components/dialogs/TransferDialog';
import React from 'react';
import ManualConnectBankDialog from '../ManualConnectBankDialog';

const LandaDialogs = () => {
  return (
    <>
      <TransferDialog />
      <ManualConnectBankDialog />
    </>
  );
};

export default LandaDialogs;
