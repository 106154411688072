import TransactionChips from 'components/dialogs/TransferDialog/components/ChooseAmount/components/TransactionChips';
import {
  TRANSACTION_FREQUENCY_OPTIONS,
  TRANSACTION_TYPE_OPTIONS,
} from 'components/dialogs/TransferDialog/components/ChooseAmount/utils';
import TransactionFormContainer from 'components/dialogs/TransferDialog/components/TransactionFormContainer';
import { useTransaction } from 'components/dialogs/TransferDialog/context';
import { FREQUENCY_MESSAGES } from 'components/dialogs/TransferDialog/context/utils';
import Button from 'components/generics/buttons/Button';
import { FlowComponentProps } from 'components/generics/flows/Flow';
import MoneyInput from 'components/generics/inputs/MoneyInput';
import Typography from 'components/generics/texts/Typography';
import LandaSelect from 'components/generics/toggles/LandaSelect';
import useRecurringActions from 'hooks/requests/account/useRecurringActions';
import useUserCash from 'hooks/requests/portfolio/useUserCash';
import { TransactionType } from 'pages/activity/components/ActivityTable/types/transaction';
import React from 'react';
import Select from 'react-select';
import { ConflictingOrderType } from 'types/order-types';
import { RecurringActionInterval } from 'types/recurring-action-types';
import { parseMoney } from 'utils/money_utils';
import classes from './index.module.scss';

const ChooseAmount = ({ onFinish }: FlowComponentProps) => {
  const [loading, setLoading] = React.useState(false);
  const { withdrawableCash } = useUserCash();
  const {
    valid,
    transactionType,
    setTransactionType,
    errorMessage,
    onValueChange,
    getSpeedOptions,
    getConflictingOrders,
    amount,
    setTransactionFrequency,
    transactionFrequency,
    transactionLimits,
    transactionLimitsLoading,
  } = useTransaction();

  const onClick = async () => {
    setLoading(true);
    await getSpeedOptions();
    let conflictingOrders: ConflictingOrderType[] = [];
    if (transactionType === TransactionType.WITHDRAWAL) {
      conflictingOrders = (await getConflictingOrders(
        parseFloat(amount as string)
      )) as ConflictingOrderType[];
    }
    setLoading(false);
    onFinish({
      conflictingOrders,
    });
  };

  const {
    performingRecurringActions,
    loading: performingRecurringActionsLoading,
  } = useRecurringActions();

  const isAmountValid =
    transactionLimits?.maxAmount > parseFloat(amount || '0');

  return (
    <TransactionFormContainer className={classes.container}>
      <Typography className={classes.title}>Transfer Money</Typography>

      <div className={classes.row}>
        <Typography className={classes.left}>Type</Typography>
        <LandaSelect
          defaultValue={TRANSACTION_TYPE_OPTIONS[0]}
          className={classes.right}
          onChange={(option) =>
            setTransactionType(option?.value || TransactionType.DEPOSIT)
          }
          options={TRANSACTION_TYPE_OPTIONS}
        />
      </div>

      <div className={classes.row}>
        <Typography className={classes.left}>Amount</Typography>
        <div className={classes.right}>
          <MoneyInput
            autoFocus={true}
            onValueChange={onValueChange}
            value={amount}
          />
        </div>
      </div>

      {transactionType === TransactionType.DEPOSIT && (
        <div className={classes.row}>
          <TransactionChips className={classes.interactive} />
        </div>
      )}

      {transactionType === TransactionType.DEPOSIT && (
        <div className={classes.row}>
          <Typography className={classes.left}>Frequency</Typography>
          <Select
            isDisabled={
              performingRecurringActionsLoading ||
              performingRecurringActions?.length > 0
            }
            defaultValue={TRANSACTION_FREQUENCY_OPTIONS[0]}
            className={classes.right}
            onChange={(option) =>
              setTransactionFrequency(
                option?.value || RecurringActionInterval.JUST_ONCE
              )
            }
            options={TRANSACTION_FREQUENCY_OPTIONS}
          />
        </div>
      )}

      <div className={classes.bottomSection}>
        {transactionType === TransactionType.WITHDRAWAL && (
          <Typography className={classes.subtitle}>
            Total Cash: {parseMoney(withdrawableCash)}
          </Typography>
        )}
        {transactionFrequency !== RecurringActionInterval.JUST_ONCE && (
          <Typography className={classes.subtitle}>
            {FREQUENCY_MESSAGES[transactionFrequency]}
          </Typography>
        )}
        <div className={classes.buttonContainer}>
          <Button
            className={classes.nextButton}
            disabled={!valid || !isAmountValid}
            onClick={onClick}
            loading={loading || transactionLimitsLoading}
          >
            Next
          </Button>
        </div>
        {errorMessage && (
          <Typography className={classes.errorMessage}>
            {errorMessage}
          </Typography>
        )}
        {transactionLimits?.hasLimit && !isAmountValid && (
          <Typography className={classes.errorMessage}>
            {transactionLimits?.limitComment}
          </Typography>
        )}
      </div>
    </TransactionFormContainer>
  );
};

export default ChooseAmount;
