export const DATE_OF_BIRTH_COMPLETED = 'Date of Birth Completed';
export const SSN_OR_EIN_COMPLETED = 'SSN or EIN Completed';
export const SSN_FAILED = 'SSN Failed';
export const RELATIONSHIP_COMPLETED = 'Relationship Completed';
export const EMPLOYMENT_COMPLETED = 'Employment Completed';
export const ANNUAL_INCOME_COMPLETED = 'Annual Income Completed';
export const NET_WORTH_COMPLETED = 'Net Worth Completed';
export const RISK_TOLERANCE_COMPLETED = 'Risk Tolerance Completed';
export const ACCREDITED_INVESTOR_COMPLETED = 'Accredited Investor Completed';
export const FUND_COMPLETED = 'Fund Completed';
export const AFFILIATION_COMPLETED = 'Affiliation Completed';
export const POLITICAL_EXPOSED_COMPLETED = 'Political Exposed Completed';
export const COMPANY_COMPLETED = 'Company Completed';
export const TERMS_CONFIRMED = 'Terms Confirmed';
export const ACCOUNT_TYPE_COMPLETED = 'Account Type Completed';
export const ENTITY_NAME_COMPLETED = 'Entity Name Completed';
export const FORMATION_DATE_COMPLETED = 'Formation Date Completed';
export const SIGNATORY_TITLE_COMPLETED = 'Signatory Title Completed';
export const ACCREDITATION_COMPLETED = 'Accreditation Completed';
export const KYC_REQUEST_FAILED = 'KYC Request Failed';
export const KYC_REQUEST_STARTED = 'KYC Request Started';
export const KYC_REQUEST_SUCCESS = 'KYC Request Success';
export const KYC_PROCESS_START = 'SignUp KYCProcess Start';
export const KYC_PROCESS_COMPLETE = 'SignUp KYCProcess Complete';
export const OPEN_ACCOUNT = 'Open Account Pressed';
