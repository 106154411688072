import NavBar from 'components/layouts/NavBar';
import React, { HTMLAttributes } from 'react';
import useLayoutStore from 'stores/Layout/layout';
import Drawer from '../Drawer';
import classes from './index.module.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  withDrawer?: boolean;
}

const NavBarLayout = ({ children, withDrawer = true }: Props) => {
  const navBarHeight = useLayoutStore((state) => state.navBarHeight);

  return (
    <div>
      <NavBar />
      <div className={classes.container}>
        {withDrawer && (
          <div
            style={{
              zIndex: 3,
              position: 'sticky',
              top: navBarHeight,
              height: `calc(100vh - ${navBarHeight}px)`,
            }}
          >
            <Drawer />
          </div>
        )}
        <div
          style={{
            flex: 1,
            minHeight: `calc(100vh - ${navBarHeight}px)`,
            width: '100vw',
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default NavBarLayout;
