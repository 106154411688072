export const LOGIN_ROUTE = '/login';
export const HOME_ROUTE = '/home';
export const FEED_ROUTE = '/feed';
export const ACTIVITY_ROUTE = '/activity';
export const PORTFOLIO_ROUTE = '/portfolio';
export const PROFILE_ROUTE = '/profile';
export const PROPERTY_ROUTE = '/property/[...slug]';
export const MARKET_ROUTE = '/feed/[market]';
export const LEND_ROUTE = '/lend';
export const IPO_ROUTE = '/landa-ipo';
export const PLACE_ORDER_ROUTE = '/place-order';
export const KYC_ROUTE = '/kyc';
export const KYC_STEPS_ROUTE = '/kyc/steps';
export const MISSING_FIELDS_ROUTE = '/kyc/personal-profile/missing-fields';
export const SSN_ROUTE = '/kyc/personal-profile/ssn';
export const CONNECT_AUTH_METHOD_ROUTE =
  '/kyc/personal-profile/connect-auth-method';
export const VERIFY_CONNECT_AUTH_METHOD_ROUTE =
  '/kyc/personal-profile/verify-connect-auth-method';
export const BIRTH_DATE_ROUTE = '/kyc/personal-profile/birth-date';
export const ADDRESS_ROUTE = '/kyc/personal-profile/address';
export const MANUAL_ADDRESS_ROUTE = '/kyc/personal-profile/address/manual';
export const CONFIRM_ADDRESS_ROUTE = '/kyc/personal-profile/address/confirm';
export const RELATIONSHIP_ROUTE = '/kyc/personal-profile/relationship';
export const EMPLOYMENT_ROUTE = '/kyc/personal-profile/employment';
export const ANNUAL_INCOME_ROUTE = '/kyc/investment-profile/annual-income';
export const NET_WORTH_ROUTE = '/kyc/investment-profile/net-worth';
export const RISK_TOLERANCE_ROUTE = '/kyc/investment-profile/risk-tolerance';
export const ACCREDITED_INVESTOR_ROUTE =
  '/kyc/investment-profile/accredited-investor';
export const FUND_ROUTE = '/kyc/investment-profile/fund';
export const AFFILIATIONS_ROUTE = '/kyc/investment-profile/affiliations';
export const POLITICAL_EXPOSED_ROUTE =
  '/kyc/investment-profile/affiliations/political-exposed';
export const STOCK_EXCHANGE_ROUTE =
  '/kyc/investment-profile/affiliations/stock-exchange';
export const COMPANY_ROUTE = '/kyc/investment-profile/affiliations/company';
export const KYC_VERIFY_ID_ROUTE = '/kyc/verify-id';
export const KYC_REVIEW_ROUTE = '/kyc/review';
export const KYC_ACCOUNT_TYPE_ROUTE = '/kyc/account-type';
export const KYC_ENTITY_TYPE_ROUTE = '/kyc/entity';
export const KYC_ENTITY_NAME_ROUTE = '/kyc/entity/name';
export const FORMATION_DATE_ROUTE = '/kyc/entity/formation-date';
export const SIGNATORY_TITLE_ROUTE = '/kyc/entity/signatory-title';
export const KYC_EIN_ROUTE = '/kyc/entity/ein';
export const ENTITY_ACCREDITATION_ROUTE = '/kyc/entity/accreditation';
export const ENTITY_DOCUMENTS_ROUTE = '/kyc/entity/documents';
export const SIGNUP_ROUTE = '/signup';
export const FULL_NAME_ROUTE = '/full-name';
export const TERMS_ROUTE = 'https://www.landa.app/terms';
export const PRIVACY_POLICY_ROUTE = 'https://www.landa.app/privacy-policy';
export const PLACE_ORDER_PROCESSING_ROUTE = '/place-order/processing';
export const NOT_FOUND_ROUTE = '/_error';
export const RESIDENTS_RENT_PAY_ROUTE = '/residents/rent-pay';
export const LANDA_APP = 'https://www.landa.app';
export const RESIDENTS_RENT_SIGN_RENT_ROUTE = '/residents/sign-lease';
export const RESIDENTS_SIGNUP_ROUTE = '/residents/signup';
export const RESIDENTS_KYC_ROUTE = '/residents/kyc';
export const RESIDENTS_KYC_STEPS_ROUTE = '/residents/kyc/steps';

export const RESIDENTS_KYC_ACCOUNT_TYPE_ROUTE = '/residents/kyc/account-type';
export const RESIDENTS_ANNUAL_INCOME_ROUTE =
  '/residents/kyc/investment-profile/annual-income';
export const RESIDENTS_NET_WORTH_ROUTE =
  '/residents/kyc/investment-profile/net-worth';
export const RESIDENTS_SSN_ROUTE = '/residents/kyc/personal-profile/ssn';

export const RESIDENTS_CONNECT_AUTH_METHOD_ROUTE =
  '/residents/kyc/personal-profile/connect-auth-method';
export const RESIDENTS_ADDRESS_ROUTE =
  '/residents/kyc/personal-profile/address';
export const RESIDENTS_CONFIRM_ADDRESS_ROUTE =
  '/residents/kyc/personal-profile/address/confirm';
export const RESIDENTS_MANUAL_ADDRESS_ROUTE =
  '/residents/kyc/personal-profile/address/manual';
export const RESIDENTS_RELATIONSHIP_ROUTE =
  '/residents/kyc/personal-profile/relationship';
export const RESIDENTS_EMPLOYMENT_ROUTE =
  '/residents/kyc/personal-profile/employment';
export const RESIDENTS_KYC_REVIEW_ROUTE = '/residents/kyc/review';
