import classNames from 'classnames';
import DrawerItem from 'components/layouts/Drawer/components/DrawerItem';
import LandaTheme from 'components/layouts/LandaTheme';
import useResponsive from 'hooks/logics/useResponsive';
import React, { HTMLAttributes, useCallback } from 'react';
import { THEMEABLE_COMPONENTS } from 'stores/LandaTheme/landaTheme';
import useLayoutStore from 'stores/Layout/layout';
import DrawerToggleButton from './components/DrawerToggleButton';
import { NAV_BAR_ROUTES, NAV_BAR_ROUTES_MOBILE } from './consts';
import classes from './index.module.scss';

const Drawer = ({ className }: HTMLAttributes<HTMLDivElement>) => {
  const { isMobile } = useResponsive();
  const { drawerOpen, setDrawerOpen } = useLayoutStore();
  const shouldExpand = !isMobile && drawerOpen;
  const toggleExpand = useCallback(
    () => setDrawerOpen(!drawerOpen),
    [drawerOpen]
  );

  const navBarRoutes = isMobile ? NAV_BAR_ROUTES_MOBILE : NAV_BAR_ROUTES;

  return (
    <LandaTheme id={THEMEABLE_COMPONENTS.DRAWER}>
      <div className={classNames(classes.drawer, className)}>
        <DrawerToggleButton
          className={classes.toggle}
          expand={drawerOpen}
          toggleExpand={toggleExpand}
        />
        <div className={classes.navigation}>
          {navBarRoutes.map((item) => (
            <DrawerItem
              {...item}
              key={item.name}
              expand={shouldExpand}
            />
          ))}
        </div>
      </div>
    </LandaTheme>
  );
};

export default Drawer;
