import RegularLayout from 'components/layouts/RegularLayout';
import {
  ACTIVITY_ROUTE,
  FEED_ROUTE,
  HOME_ROUTE,
  IPO_ROUTE,
  KYC_ROUTE,
  KYC_VERIFY_ID_ROUTE,
  LEND_ROUTE,
  LOGIN_ROUTE,
  MARKET_ROUTE,
  NOT_FOUND_ROUTE,
  PLACE_ORDER_ROUTE,
  PORTFOLIO_ROUTE,
  PROFILE_ROUTE,
  PROPERTY_ROUTE,
  RESIDENTS_KYC_REVIEW_ROUTE,
  RESIDENTS_KYC_ROUTE,
  RESIDENTS_RENT_PAY_ROUTE,
  RESIDENTS_RENT_SIGN_RENT_ROUTE,
  RESIDENTS_SIGNUP_ROUTE,
  SIGNUP_ROUTE,
} from 'consts/routes/urls';
import React from 'react';
import IntroLayout from './IntroLayout';
import NavBarLayout from './NavBarLayout';

export type LayoutItem = {
  layout: React.FC;
  props: object;
};

const NavBarWithoutFooter = {
  layout: NavBarLayout,
  props: {
    withFooter: false,
  },
};

const NavBarWithoutDrawer = {
  layout: NavBarLayout,
  props: {
    withDrawer: false,
  },
};

export const DEFAULT_LAYOUT = React.Fragment;
export const ROUTES_TO_LAYOUTS: { [key: string]: React.FC | LayoutItem } = {
  [HOME_ROUTE]: NavBarWithoutFooter,
  [LOGIN_ROUTE]: RegularLayout,
  [SIGNUP_ROUTE]: RegularLayout,
  [KYC_ROUTE]: IntroLayout,
  [KYC_VERIFY_ID_ROUTE]: RegularLayout,
  [ACTIVITY_ROUTE]: NavBarLayout,
  [PROPERTY_ROUTE]: NavBarLayout,
  [LEND_ROUTE]: NavBarLayout,
  [PORTFOLIO_ROUTE]: NavBarLayout,
  [PLACE_ORDER_ROUTE]: NavBarWithoutDrawer,
  [FEED_ROUTE]: NavBarWithoutFooter,
  [MARKET_ROUTE]: NavBarWithoutFooter,
  [PROFILE_ROUTE]: NavBarLayout,
  [IPO_ROUTE]: NavBarLayout,
  [NOT_FOUND_ROUTE]: NavBarLayout,
  [RESIDENTS_SIGNUP_ROUTE]: RegularLayout,
  [RESIDENTS_RENT_PAY_ROUTE]: NavBarWithoutDrawer,
  [RESIDENTS_RENT_SIGN_RENT_ROUTE]: NavBarWithoutDrawer,
  [RESIDENTS_KYC_ROUTE]: IntroLayout,
  [RESIDENTS_KYC_REVIEW_ROUTE]: IntroLayout,
};
