import { merge } from 'lodash';
import { createAndResetOnLogout } from 'stores/utils';
import {
  InvestmentProfile,
  KYCIdentities,
  PersonalInformation,
} from 'types/kyc-identities-types';
import { persist } from 'zustand/middleware';

export interface KycStore {
  kycIdentities: KYCIdentities;
  setKycIdentities: (kycIdentities: KYCIdentities) => void;
  setPersonalInformation: (personalInformation: PersonalInformation) => void;
  setInvestmentProfile: (investmentProfile: InvestmentProfile) => void;
}

export const useKycStore = createAndResetOnLogout<KycStore>()(
  persist(
    (set) => ({
      kycIdentities: {} as KYCIdentities,
      setKycIdentities: (newKycIdentities: KYCIdentities) =>
        set(({ kycIdentities }) => ({
          kycIdentities: merge(
            {
              ...kycIdentities,
              attributes: {
                ...kycIdentities.attributes,
                personal_information: undefined,
              },
            },
            newKycIdentities
          ),
        })),
      setPersonalInformation: (personalInformation: PersonalInformation) =>
        set(({ kycIdentities }) => ({
          kycIdentities: {
            ...kycIdentities,
            attributes: {
              ...kycIdentities.attributes,
              personal_information: personalInformation,
            },
          },
        })),
      setInvestmentProfile: (investmentProfile: InvestmentProfile) =>
        set(({ kycIdentities }) => ({
          kycIdentities: {
            ...kycIdentities,
            attributes: {
              ...kycIdentities.attributes,
              investment_profile: investmentProfile,
            },
          },
        })),
    }),
    { name: 'kyc' }
  )
);
