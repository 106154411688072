import { ShakeRef } from 'components/containers/ShakeableComponent';
import {
  TransactionLimits,
  TransactionSpeed,
  TransactionType,
} from 'pages/activity/components/ActivityTable/types/transaction';
import { RefObject } from 'react';
import { ConflictingOrderType } from 'types/order-types';
import { RecurringActionInterval } from 'types/recurring-action-types';

export type TransactionContextType = {
  amount?: string;
  valid: boolean;
  onValueChange: (amount?: string) => void;
  speed: TransactionSpeed;
  setSpeed: (speed: TransactionSpeed) => void;
  transactionType: TransactionType;
  setTransactionType: (transactionType: TransactionType) => void;
  response: {
    success: boolean;
    errorMessage: string;
  };
  setResponse: (response: { success: boolean; errorMessage: string }) => void;
  getSpeedOptions: () => void;
  speedOptions?: SpeedOptions;
  fastDayText?: string;
  ordinaryDayText?: string;
  settlementText?: string;
  getConflictingOrders: (amount: number) => Promise<ConflictingOrderType[]>;
  shakeRef: RefObject<ShakeRef>;
  errorMessage: string;
  nextLoading: boolean;
  transactionFrequency: RecurringActionInterval;
  transactionLimits: TransactionLimits;
  transactionLimitsLoading: boolean;
  setTransactionFrequency: (frequency: RecurringActionInterval) => void;
};

export type SpeedOptions = {
  settlementDateOrdinary: Date;
  settlementDateFast: Date;
  sameDayPricing: number;
  showSameDayOption: boolean;
};

export const FREQUENCY_MESSAGES = {
  [RecurringActionInterval.WEEKLY]:
    'This deposit will be initiated every Monday.',
  [RecurringActionInterval.MONTHLY]:
    'This deposit will be initiated on the 1st of every month.',
  [RecurringActionInterval.TWICE_A_MONTH]:
    'This deposit will be initiated on the 1st and 15th of every month.',
  [RecurringActionInterval.QUARTERLY]:
    'This deposit will be initiated on the 1st of January, April, July, and October.',
};
