import { EntityDetails } from 'context/KYC/types';
import { SelectOption } from 'types';

export const ACCOUNT_TYPE_OPTIONS: Array<SelectOption> = [
  {
    value: 'Individual',
    label: 'Individual',
  },
  {
    value: 'Entity',
    label: 'Entity (LLC, Corporation or Trust)',
  },
];

export const ENTITY_DETAILS_INITIAL: EntityDetails = {
  entityName: '',
  entityAccreditation: [],
  entityType: '',
  signatoryTitle: '',
  encryptedEin: '',
  dateOfFormation: '',
};

export const ENTITY_TYPE_OPTIONS = {
  LLC: 'LLC',
  corporation: 'Corporation (C-Corp, S-Corp, B-Corp)',
  limited_partnership: 'Limited Partnership',
  irrevocable_trust: 'Irrevocable Trust',
  revocable_trust: 'Revocable Trust',
};

export const ENTITY_ACCREDITATION_OPTIONS = {
  each_owner_1m:
    'The net worth of each owner is $1M+ (excluding primary residence)',
  each_owner_200k:
    'The individual income of each owner was $200k+ for each of the past two years',
  each_owner_300k:
    'For each owner, their joint income including their spouse was $300k+ for each of the past two years',
  exceeding_5m:
    'For each owner, their joint income including their spouse was $300k+ for each of the past two years',
  associated_with_finra_member:
    'Are you or anyone in your household associated with a FINRA Member?',
  related_to_share_holder:
    'Are you or anyone in your household or immediate family a 10% shareholder, officer, or member of the board of directors of a publicly traded company?',
};
