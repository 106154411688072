import useAuth from 'context/Auth';
import { PORTFOLIO_QUERY } from 'graphql/portfolio/portfolioQuery';
import { useLandaApiLazyQuery } from 'hooks/api/graphql/landa_api';
import { useEffect } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { usePortfolioStore } from 'stores/Portfolio/portfolio';
import { PortfolioType } from 'types/portfolio-types';

const init: {
  portfolio?: PortfolioType;
  loading: boolean;
  request?: () => void;
} = {
  portfolio: undefined,
  loading: false,
  request: undefined,
};

const usePortfolio = () => {
  const { isLoggedIn } = useAuth();
  const { portfolio, setPortfolio } = usePortfolioStore();
  const [innerRequest, { loading }] = useLandaApiLazyQuery(PORTFOLIO_QUERY, {
    fetchPolicy: 'network-only',
  });

  const request = () => {
    innerRequest().then((res) => {
      setPortfolio(res?.data?.account);
    });
  };

  useEffect(() => {
    if (!isLoggedIn) return;
    request();
  }, [isLoggedIn]);

  return {
    request,
    portfolio,
    loading,
  };
};

export default singletonHook(init, usePortfolio);
