import {
  ConnectorSDKOnExitSuccessCallback,
  useQuilttSession,
} from '@quiltt/react';
import useFinancials from 'hooks/requests/financials/useFinancials';
import useBankStateSectionData from 'pages/profile/components/ProfileTabContent/Bank&Transfers/components/BankStateSection/useBankStateSectionData';
import useGlobalLoader from 'stores/GlobalLoader/globalLoader';

export const useConnectBankAccountModal = (onClose: () => void) => {
  const { session } = useQuilttSession();
  const { request } = useFinancials();
  const { setBankLoading } = useGlobalLoader();
  const { accountDetails } = useBankStateSectionData();

  const handleExitSuccess: ConnectorSDKOnExitSuccessCallback = async (
    metadata
  ) => {
    if (metadata && session) {
      try {
        setBankLoading(true);
        onClose();
        await accountDetails({
          variables: {
            profile_id: metadata.profileId,
            connector_id: metadata.connectorId,
            connection_id: metadata.connectionId,
            user_token: session.token,
          },
        });
        request?.();
        setBankLoading(false);
      } catch (error) {
        setBankLoading(false);
        console.log(error);
      }
    }
  };

  return {
    handleExitSuccess,
  };
};
