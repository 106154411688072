import { ENTITY_DETAILS_INITIAL } from 'consts/kyc/options/entity';
import {
  AFFILIATION_INITIAL,
  POLITICALLY_EXPOSED_PERSON_INITIAL,
} from 'consts/kyc/options/kyc';
import {
  AccountType,
  AddressGeometry,
  EntityDetails,
  KYCContextType,
  UserInputAddress,
} from 'context/KYC/types';
import dateFormat from 'dateformat';
import useKyc from 'hooks/requests/kyc/useKyc';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Affiliations, Employed, PoliticallyExposedPerson } from 'types';
import { adjustAddressToBackend } from 'utils/formatters';

const KYCContext = createContext<KYCContextType>({} as KYCContextType);

export const KYCProvider = ({ children }: PropsWithChildren) => {
  // All the data that is needed to be sent to the backend
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [date, setDate] = useState<string>('');
  const [ssn, setSSN] = useState<string>('');
  const [address, setAddress] = useState<UserInputAddress>();
  const [addressGeometry, setAddressGeometry] = useState<AddressGeometry>();
  const [relationship, setRelationship] = useState<string>('');
  const [employment, setEmployment] = useState<string | Employed>();
  const [annualIncome, setAnnualIncome] = useState<string>('');
  const [annualIncomeValue, setAnnualIncomeValue] = useState<string>('');
  const [accreditedInvestor, setAccreditedInvestor] = useState<string>('');
  const [netWorth, setNetWorth] = useState<string>('');
  const [netWorthValue, setNetWorthValue] = useState<string>('');
  const [riskTolerance, setRiskTolerance] = useState<string>('');
  const [fund, setFund] = useState<string>('');
  const [affiliations, setAffiliations] =
    useState<Affiliations>(AFFILIATION_INITIAL);
  const [stockExchange, setStockExchange] = useState<string>('');
  const [publiclyTradedCompany, setPubliclyTradedCompany] =
    useState<string>('');
  const [politicallyExposedPerson, setPoliticallyExposedPerson] =
    useState<PoliticallyExposedPerson>(POLITICALLY_EXPOSED_PERSON_INITIAL);
  const [accountType, setAccountType] = useState<AccountType>();
  const [entityDetails, setEntityDetails] = useState<EntityDetails>(
    ENTITY_DETAILS_INITIAL
  );

  // other stuff
  const personalProfileCompleted =
    !!date && !!ssn && !!address && !!relationship && !!employment;

  // finalize stuff to backed
  const finalAddress = adjustAddressToBackend(address);

  const collectedData = {
    accountKind: accountType,
    email: email,
    phoneNumber: phone,
    address: finalAddress,
    dateOfBirth: date,
    encryptedSsn: ssn,
    entityQuestionnaire: entityDetails,
    isSignedTerms: true,
    questionnaire: {
      accreditedType: '',
      adventurousType: riskTolerance,
      annualIncome: annualIncome,
      annualIncomeValue: +annualIncomeValue || '',
      employmentStatus: {
        type: employment,
        jobTitle: (employment as Employed)?.role,
        employerName: (employment as Employed)?.company,
        occupationIndustry: (employment as Employed)?.industry,
      },
      howWillYouFund: fund,
      investorType: accreditedInvestor,
      maritalStatus: relationship,
      netWorth: netWorth,
      netWorthValue: +netWorthValue || '',
      pepAffiliated:
        politicallyExposedPerson !== POLITICALLY_EXPOSED_PERSON_INITIAL
          ? 'yes'
          : 'no',
      pepEmployerName: politicallyExposedPerson.Name,
      pepGovernmentBranch: politicallyExposedPerson['Government branch'],
      pepPosition: politicallyExposedPerson['Job title'],
      pepWealthSource: politicallyExposedPerson['Source of wealth'],
      stockExchangeEmployed: stockExchange ? 'yes' : 'no',
      stockExchangeFirmName: stockExchange,
      tradedCompany: publiclyTradedCompany ? 'yes' : 'no',
      tradedCompanyName: publiclyTradedCompany,
    },
  };

  // Hack to get the date of birth from the kycIdentities and skip this page if it's already filled
  const { kycIdentities } = useKyc();

  useEffect(() => {
    if (kycIdentities?.attributes?.date_of_birth) {
      setDate(dateFormat(kycIdentities.attributes.date_of_birth, 'yyyy-mm-dd'));
    }
  }, [kycIdentities?.attributes?.date_of_birth]);

  return (
    <KYCContext.Provider
      value={{
        setEmail,
        setPhone,
        date,
        setDate,
        ssn,
        setSSN,
        address,
        setAddress,
        addressGeometry,
        setAddressGeometry,
        relationship,
        setRelationship,
        employment,
        setEmployment,
        personalProfileCompleted,
        annualIncome,
        setAnnualIncome,
        annualIncomeValue,
        setAnnualIncomeValue,
        accreditedInvestor,
        setAccreditedInvestor,
        netWorth,
        setNetWorth,
        netWorthValue,
        setNetWorthValue,
        riskTolerance,
        setRiskTolerance,
        fund,
        setFund,
        affiliations,
        setAffiliations,
        stockExchange,
        setStockExchange,
        publiclyTradedCompany,
        setPubliclyTradedCompany,
        politicallyExposedPerson,
        setPoliticallyExposedPerson,
        collectedData,
        accountType,
        setAccountType,
        entityDetails,
        setEntityDetails,
      }}
    >
      {children}
    </KYCContext.Provider>
  );
};

export default function useKYC() {
  return useContext(KYCContext);
}
