import { ShakeRef } from 'components/containers/ShakeableComponent';
import useConflictingOrders from 'components/dialogs/TransferDialog/context/hooks/useConflictingOrders';
import useTransactionAmount from 'components/dialogs/TransferDialog/context/hooks/useTransactionAmount';
import useTransactionResponse from 'components/dialogs/TransferDialog/context/hooks/useTransactionResponse';
import useTransactionSpeed from 'components/dialogs/TransferDialog/context/hooks/useTransactionSpeed';
import { TransactionContextType } from 'components/dialogs/TransferDialog/context/utils';
import { TransactionType } from 'pages/activity/components/ActivityTable/types/transaction';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useRef,
  useState,
} from 'react';
import { RecurringActionInterval } from 'types/recurring-action-types';
import useTransactionLimits from '../hooks/useTransactionLimits';

const TransactionContext = createContext<TransactionContextType>(
  {} as TransactionContextType
);

interface Props extends PropsWithChildren {
  type?: TransactionType;
}

export const TransactionProvider = ({ children, type }: Props) => {
  const [transactionType, setTransactionType] = useState(
    type || TransactionType.DEPOSIT
  );
  const [transactionFrequency, setTransactionFrequency] =
    useState<RecurringActionInterval>(RecurringActionInterval.JUST_ONCE);

  const shakeRef = useRef<ShakeRef>(null);

  const {
    getSpeedOptions,
    speedOptions,
    speed,
    setSpeed,
    fastDayText,
    ordinaryDayText,
    settlementText,
  } = useTransactionSpeed(transactionType);

  const { onValueChange, valid, errorMessage, nextLoading, amount } =
    useTransactionAmount(transactionType, shakeRef);

  const { response, setResponse } = useTransactionResponse();
  const getConflictingOrders = useConflictingOrders();
  const { transactionLimits, loading: transactionLimitsLoading } =
    useTransactionLimits(transactionType);

  return (
    <TransactionContext.Provider
      value={{
        shakeRef,
        transactionType,
        setTransactionType,
        speed,
        setSpeed,
        speedOptions,
        getSpeedOptions,
        onValueChange,
        valid,
        errorMessage,
        nextLoading,
        amount,
        response,
        setResponse,
        fastDayText,
        ordinaryDayText,
        settlementText,
        transactionLimits,
        getConflictingOrders,
        transactionFrequency,
        setTransactionFrequency,
        transactionLimitsLoading,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};

export const useTransaction = () => {
  return useContext(TransactionContext);
};
