import { FC } from 'react';
import Modal from 'components/containers/Modal/Modal';
import classNames from 'classnames';
import IconButton from 'components/generics/buttons/IconButton';
import Typography from 'components/generics/texts/Typography';
import Button from 'components/generics/buttons/Button';
import { QuilttButton } from '@quiltt/react';
import classes from './index.module.scss';
import { useConnectBankAccountModal } from 'hooks/logics/useConnectBankAccountModal';

interface Props {
  modalOpen: boolean;
  onClose: () => void;
  onClick: () => void;
  loading?: boolean;
}

const connectorId = process.env.NEXT_PUBLIC_QUILTT_CONNECTOR_ID || '';

const BankConnectionModal: FC<Props> = ({ onClose, onClick, modalOpen }) => {
  const { handleExitSuccess } = useConnectBankAccountModal(onClose);
  return (
    <Modal
      className={classNames(classes.modalContainer)}
      isOpen={modalOpen}
      onClose={onClose}
    >
      <div className={classes.topContainer}>
        <IconButton
          className={classes.closeIcon}
          onClick={onClose}
          variant="close"
        />
        <Typography className={classes.text}>
          Select your way to connect bank account
        </Typography>
      </div>
      <div className={classNames(classes.childrenContainer)}>
        <Button
          onClick={onClick}
          className={classes.connectBankButton}
        >
          Connect Manually
        </Button>
        <QuilttButton
          connectorId={connectorId}
          onExitSuccess={handleExitSuccess}
          className={classes.connectBankButton}
        >
          <Typography className={classes.button}>
            Connect with Quiltt
          </Typography>
        </QuilttButton>
      </div>
    </Modal>
  );
};

export default BankConnectionModal;
